import React, { useState } from 'react';
import { Box, InputBase, Typography, Popper, ClickAwayListener } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const Prompts = ({ messages }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Box sx={{ width: '100%', margin: '0 auto' }}>
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                backgroundColor: '#141314',
                borderRadius: 10, 
                border: '1px solid #141314', 
                position: 'relative',
                padding: '0 10px',
                height: '40px'
            }}>
                <AttachFileIcon sx={{ 
                    color: '#BFECFF', 
                    marginRight: 1 
                }} />
                <InputBase
                    placeholder="Message ChatGPT..."
                    onClick={handleClick}
                    sx={{ 
                        color: '#6A6A6A', 
                        flexGrow: 1,
                        height: '100%',
                        backgroundColor: '#141314',
                        borderRadius: '4px', 
                        padding: '8px 40px 8px 8px', 
                        '& .MuiInputBase-input': {
                            padding: '8px 0',
                            '&::placeholder': {
                                color: 'lightblue', 
                            },
                        },
                    }}
                />
                <ArrowUpwardIcon sx={{ 
                    color: '#BFECFF', 
                    marginLeft: 1 
                }} />
            </Box>

            <Popper open={isOpen} anchorEl={anchorEl} placement="top-start">
                <ClickAwayListener onClickAway={handleClose}>
                    <Box sx={{ 
                        backgroundColor: '#141314', 
                        borderRadius: 2, 
                        padding: 2, 
                        maxHeight: '300px', 
                        overflowY: 'auto',
                        mb: 1,
                        width: anchorEl ? anchorEl.offsetWidth : 'auto',
                        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)'
                    }}>
                        {messages.map((msg, index) => (
                            <Box key={index} sx={{ mb: 1 }}>
                                <Typography variant="body2" sx={{ color: 'white' }}>
                                    {index + 1}. {msg}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </ClickAwayListener>
            </Popper>
        </Box>
    );
};

export default Prompts;