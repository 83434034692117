import React, { lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './components/authentication/AuthContext';
import { UserProvider } from './components/UserContext';
import PrivateRoute from './components/authentication/PrivateRoute';
import Login from './components/authentication/Login';
import { LayoutMain } from './components/LayoutMain';
import './firebase';

const AzureMap = lazy(() => import('./components/AzureMap'));

function App() {
  return (
   <AuthProvider>
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route 
            path="/*" 
            element={
              <PrivateRoute>
                <LayoutMain />
              </PrivateRoute>
            } 
          />
        </Routes>
      </Router>
    </UserProvider>
  </AuthProvider>
  );
}

export default App;