import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const GlobalKPIs = () => {
  const kpiData = [
    { label: '% of Aging Inventory in Hubs', target: '<15%', actual: '23%', status: 'High Risk' },
    { label: 'Inventory Turns', target: '2.5', actual: '3.4', status: 'High Risk' },
    { label: 'Nodes out for Repair', target: '0.05% of Fleet', actual: '0.065%', status: 'Moderate Risk' },
    { label: 'Deployment Readiness by PDD Date', target: '97%', actual: '94%', status: 'Moderate Risk' },
    { label: 'Hub Cycle Time', target: '<7 Days', actual: '6.4', status: 'On Track' },
    { label: 'Land Numbers of Racks Built', target: '22.6K in Q4', actual: '7.1K in June', status: 'On Track' },
  ];

  const additionalData = [
    { label: 'Number of Operational Datacenters:', value: '328' },
    { label: 'Number of Datacenters in Deployment:', value: '32' },
    { label: 'Number of Datacenters under Construction:', value: '150' },
    { label: 'Number of Operational Warehouses:', value: '61' },
    { label: 'Ships in Transit to Ports:', value: '74' },
    { label: 'Planes in Transit:', value: '43' },
    { label: 'Trains in Transit:', value: '54' },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case 'High Risk':
        return 'red';
      case 'Moderate Risk':
        return 'orange';
      case 'On Track':
        return 'green';
      default:
        return 'black';
    }
  };

  return (
    <Box sx={{ width: '97%', padding: 2, backgroundColor: '#292930' }}>
      <TableContainer sx={{ width: '100%', boxShadow: 'none', backgroundColor: 'transparent', overflowX: "hidden" }}>
        <Table stickyHeader aria-label="global kpis table" sx={{ 
          width: '100%', 
          backgroundColor: 'transparent', 
          '& .MuiTableCell-root': { 
            border: 'none', 
            padding: '2px 4px', 
            backgroundColor: 'transparent' 
          }, 
          'border-spacing': '0 5px', 
          'border-collapse': 'separate', 
        }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'left', border: 'none', fontSize: '14px', width: '40%' }}>Global KPIs</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'left', border: 'none', fontSize: '14px', width: '25%' }}>Target</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'left', border: 'none', fontSize: '14px', width: '18%' }}>Actual</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'left', border: 'none', fontSize: '14px', width: '22%' }}>Status</TableCell> 
            </TableRow>
          </TableHead>
          <TableBody>
            {kpiData.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ color: 'white', fontSize: '12px', verticalAlign: 'top' }}>{row.label}</TableCell>
                <TableCell sx={{ color: 'white', fontSize: '12px', verticalAlign: 'top' }}>{row.target}</TableCell>
                <TableCell sx={{ color: 'white', fontSize: '12px', verticalAlign: 'top' }}>{row.actual}</TableCell>
                <TableCell sx={{ color: 'white', fontSize: '11px', verticalAlign: 'top' }}>
                  <Box sx={{ 
                    backgroundColor: getStatusColor(row.status),
                    padding: '2px 4px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                  }}>
                    {row.status}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer sx={{ width: '100%', boxShadow: 'none', backgroundColor: 'transparent', marginTop: 2 }}>
        <Table aria-label="additional data table" sx={{ 
          width: '100%', 
          backgroundColor: 'transparent', 
          '& .MuiTableCell-root': { 
            border: 'none', 
            padding: '2px 4px', 
            backgroundColor: 'transparent' 
          }, 
        }}>
          <TableBody>
            {additionalData.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ color: 'white', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'top' }}>{row.label}</TableCell>
                <TableCell sx={{ color: 'white', fontSize: '12px', verticalAlign: 'top' }}>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default GlobalKPIs;
