import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const filterOptions = {
  "region-filter": {
    "filter-name": "Region(s)",
    "filter-value": "DCOPSREGION",
    "filter-type": "Normal",
    "options": [
      { "value": "APAC", "label": "APAC" },
      { "value": "Canada", "label": "Canada" },
      { "value": "EMEA", "label": "EMEA" },
      { "value": "LATAM", "label": "LATAM" },
      { "value": "US East", "label": "US East" },
      { "value": "US West", "label": "US West" }
    ]
  },
  "entity-filter": {
    "filter-name": "Deployment Timing(s)",
    "filter-value": "EntityType",
    "filter-type": "Normal",
    "options": [
      { "value": "early", "label": "Projected Early" },
      { "value": "onTime", "label": "Projected On-Time" },
      { "value": "maybeLate", "label": "Projected Late" },
      { "value": "defLate", "label": "Delayed" },
      { "value": "noDeployment", "label": "No Deployment" }
    ]
  },
  "owner-filter": {
    "filter-name": "Facility Owner",
    "filter-value": "FACILITYOWNER",
    "filter-type": "Normal",
    "options": [
      { "value": "Microsoft", "label": "Microsoft" },
      { "value": "-Microsoft", "label": "Other" }
    ]
  }
};

const FilterManager = ({ onFilterChange, mode }) => {
  
  const [filters, setFilters] = useState({
        'region-filter': '',
        'entity-filter': '',
        'owner-filter': ''
  });

  const handleFilterChange = (filterKey, value) => {
    const newFilters = {
      ...filters,
      [filterKey]: value
    };
    setFilters(newFilters);
    onFilterChange({ [filterKey]: value === "" ? undefined : value });
  };

  const StyledSelect = styled(Select)(({ theme }) => ({
        color: 'white',
        height: '40px',
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '.MuiSvgIcon-root': {
            color: 'white',
        },
        '.MuiSelect-select': {
            color: 'white !important'
        },
    }));


    const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
        '&.MuiMenuItem-root': {
            color: 'white',
            backgroundColor: 'black',
            '&:hover': {
                color: 'black',
                backgroundColor: '#BFECFF',
        },
        '&.Mui-selected': {
            color: 'white',
            backgroundColor: '#444',
            '&:hover': {
                backgroundColor: '#555',
            },
            },
        },
    }));

  if (mode !== 'datacenters') {
    return null; 
  }

  return (
   <Box sx={{ 
      display: 'flex', 
      flexDirection: 'row', 
      gap: 2, 
      p: 2, 
      bgcolor: 'inherit',
      color: 'white',
      width: '91%'
    }}>
      {Object.entries(filterOptions).map(([key, filter]) => (
        <FormControl key={key} sx={{ 
          width: key === 'region-filter' ? 'calc(33.33% - 20px)' : 
                 key === 'entity-filter' ? 'calc(33.33% + 20px)' : 
                 'calc(33.33% - 20px)'
        }}>
          
          <InputLabel sx={{ color: '#BFECFF' }}>{filter['filter-name']}</InputLabel>
          <StyledSelect
            value={filters[key]}
            onChange={(e) => handleFilterChange(key, e.target.value)}
            label={filter['filter-name']}
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: 'black',
                  '& .MuiMenuItem-root': {
                    color: 'white',
                  },
                },
              },
            }}
          >
            <StyledMenuItem value="">
              <em>All</em>
            </StyledMenuItem>
            {filter.options.map((option) => (
              <StyledMenuItem key={option.value} value={option.value}>
                {option.label}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      ))}
    </Box>
  );
};

export default FilterManager;