// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .dual-panel-container {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  
  .panel {
    position: absolute;
    top: 0;
    height: 100%;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .left-panel {
    left: 0;
    height: 100%;
    background-color: black;
  }
  
  .right-panel {
    height: 100%;
    background-color: grey;
  }
  
  .slider {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: #888;
    cursor: col-resize;
    transition: background-color 0.3s;
  }
  
  .slider:hover {
    background-color: #666;
  }
  
  .slider-knob {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 2px solid #888;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .arrow {
    font-size: 14px;
    font-weight: bold;
    color: #888;
  }
  
  .arrow.left {
    margin-right: 2px;
  }
  
  .arrow.right {
    margin-left: 2px;
  }`, "",{"version":3,"sources":["webpack://./src/css/DualPanel.css"],"names":[],"mappings":"CAAC;IACG,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,YAAY;IACZ,cAAc;IACd,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,OAAO;IACP,YAAY;IACZ,uBAAuB;EACzB;;EAEA;IACE,YAAY;IACZ,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,UAAU;IACV,sBAAsB;IACtB,kBAAkB;IAClB,iCAAiC;EACnC;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[" .dual-panel-container {\n    position: relative;\n    height: 100%;\n    overflow: hidden;\n  }\n  \n  .panel {\n    position: absolute;\n    top: 0;\n    height: 100%;\n    overflow: auto;\n    padding: 20px;\n    box-sizing: border-box;\n  }\n  \n  .left-panel {\n    left: 0;\n    height: 100%;\n    background-color: black;\n  }\n  \n  .right-panel {\n    height: 100%;\n    background-color: grey;\n  }\n  \n  .slider {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    width: 4px;\n    background-color: #888;\n    cursor: col-resize;\n    transition: background-color 0.3s;\n  }\n  \n  .slider:hover {\n    background-color: #666;\n  }\n  \n  .slider-knob {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    width: 30px;\n    height: 30px;\n    background-color: #fff;\n    border: 2px solid #888;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .arrow {\n    font-size: 14px;\n    font-weight: bold;\n    color: #888;\n  }\n  \n  .arrow.left {\n    margin-right: 2px;\n  }\n  \n  .arrow.right {\n    margin-left: 2px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
