import React, { useEffect, useRef, useState, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { AuthContext } from './authentication/AuthContext';
import { Box, AppBar, Toolbar, InputBase, IconButton, Button, List, ListItem, Typography, Badge} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NotificationIcon from '@mui/icons-material/Notifications';
import DeploymentsTable from './DeploymentsTable';
import DualPanel from './DualPanel';
import DatacenterInfo from './DatacenterInfo';
import NavigationLeft from './NavigationLeft';
import FacilityFinder from './FacilityFinder';
import FilterManager from './FilterManager';
import DatacenterDeployments from './DatacenterDeployments';
import GlobalKPIs from './GlobalKPIs';
import Prompts from "./Prompts";
import datacentersData from '../data/datacenters.json'; 
import airportsData from '../data/airports.json';
import portsAndRoutesData from '../data/portsAndRoutes.json';
import ferryRoutesData from '../data/ferry_routes.json';
import deploymentsData from '../data/deployments.json';
import UserProfilePanel from './UserProfilePanel'; 
import { useUser } from './UserContext';
import LoadingOverlay from '@speedy4all/react-loading-overlay';

export const LayoutMain = () => {
  const navigate = useNavigate();
  const { userPreferences } = useUser();
  const [isDualPanelVisible, setIsDualPanelVisible] = useState(false);
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  const processDatacenterPreLoad_ = (userPreferences) => {
    if (userPreferences?.facilitiesSettings?.datacenterPreLoad) {
      return userPreferences.facilitiesSettings.datacenterPreLoad.map(datacenter => datacenter.value);
    }
    return [];
  };

  const processDatacenterPreLoad = (userPreferences) => {
  if (userPreferences?.facilitiesSettings?.datacenterPreLoad) {
    return userPreferences.facilitiesSettings.datacenterPreLoad.map(datacenter => {
      if (Array.isArray(datacenter.value) && datacenter.value.length === 2) {
        return datacenter.value;
      }
      console.error('Invalid datacenter value format:', datacenter.value);
      return null;
    }).filter(Boolean); 
  }
  return [];
};

  useEffect(() => {

    const delay = 4000;

    const timer = setTimeout(() => {
      setIsLoading(false);
      if (userPreferences) {
        setIsDualPanelVisible(userPreferences.defaultSettings.showFullMap !== undefined ? !userPreferences.defaultSettings.showFullMap : false);
        if (!isDualPanelVisible) {
          iframeRef.current.contentWindow.postMessage({ action: 'showFacilitiesFinderController'}, '*');
        } else {
          iframeRef.current.contentWindow.postMessage({ action: 'hideFacilitiesFinderController'}, '*');
        }
        
        if (!userPreferences.defaultSettings.showAirports) {
          iframeRef.current.contentWindow.postMessage({ action: 'hideAirports'}, '*');
        } else {
          iframeRef.current.contentWindow.postMessage({ 
            action: 'showAirports',
            apData: airportsData
          }, '*');
        }

        if (!userPreferences.defaultSettings.showSeaports) {
          console.log(" instructured to hide seaports");
          iframeRef.current.contentWindow.postMessage({ action: 'hideSeaports'}, '*');
        } else {
           iframeRef.current.contentWindow.postMessage({ 
            action: 'showSeaports',
            spData: ports
          }, '*');
        }

        const datacenterCoordinates = processDatacenterPreLoad(userPreferences);

        if (datacenterCoordinates.length > 0) {
          iframeRef.current.contentWindow.postMessage({ 
            action: 'preLoadCeisumSites',
            coordinatesList: datacenterCoordinates
          }, '*');
        } 

        iframeRef.current.contentWindow.postMessage({ 
          action: 'defaultMapReset',
          mapStyle: userPreferences.defaultSettings.mapDefaultStyle
        }, '*');

        if (userPreferences?.facilitiesSettings?.defaultView) {
          iframeRef.current.contentWindow.postMessage({ 
            action: 'setDefaultFacilityMapStyle',
            defaultFacilityMapStyle: userPreferences?.facilitiesSettings?.defaultView
          }, '*');
        }

        iframeRef.current.contentWindow.postMessage({ action: 'hideGroundTransports'}, '*');
        iframeRef.current.contentWindow.postMessage({ action: 'hideHubs'}, '*');
      }
    }, delay);

      return () => clearTimeout(timer); 
  }, [userPreferences]); 

  const facilityData = {
    FacilityName: "Quincy Datacenter",
    FacilityCoordinates: { lat: -119.85222, long: 47.23361 },
    FacilityAddress: {
      street: "123 Datacenter Ave",
      city: "Quincy",
      state: "WA",
      postalCode: "98848",
      country: "USA",
      region: "US West"
    },
    FacilityType: "Datacenter",
    FacilityLocation: "US West",
    FacilityStatus: "Operational",
    FacilityCoolingType: "Indirect Evaporative Cooling"
  };

  const filterOptions = {
    "region-filter": {
      "filter-name": "Region(s)",
      "filter-value": "DCOPSREGION",
      "filter-type": "Normal",
      "options": [
        { "value": "APAC", "label": "APAC" },
        { "value": "Canada", "label": "Canada" },
        { "value": "EMEA", "label": "EMEA" },
        { "value": "LATAM", "label": "LATAM" },
        { "value": "US East", "label": "US East" },
        { "value": "US West", "label": "US West" }
      ]
    },
    "entity-filter": {
      "filter-name": "Deployment Timing(s)",
      "filter-value": "EntityType",
      "filter-type": "Normal",
      "options": [
        { "value": "early", "label": "Projected Early" },
        { "value": "onTime", "label": "Projected On-Time" },
        { "value": "maybeLate", "label": "Projected Late" },
        { "value": "defLate", "label": "Delayed" },
        { "value": "noDeployment", "label": "No Deployment" }
      ]
    },
    "owner-filter": {
      "filter-name": "Facility Owner",
      "filter-value": "FACILITYOWNER",
      "filter-type": "Normal",
      "options": [
        { "value": "Microsoft", "label": "Microsoft" },
        { "value": "-Microsoft", "label": "Other" }
      ]
    }
  };

  const [selectedFacility, setSelectedFacility] = useState(null);
  const [selectedFacilityCoordinates, setSelectedFacilityCoordinates] = useState(null);
  
  const [searchInput, setSearchInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const iframeRef = useRef(null);
  const [DCData, setDCData] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [portData, setPortData] = useState({});
  const [isPortsDataLoaded, setIsPortsDataLoaded] = useState(false);
  const [airportData, setAirportData] = useState({});
  const [isAirportsDataLoaded, setIsAirportsDataLoaded] = useState(false);
  const [selectedPorts, setSelectedPorts] = useState([]);
  const [displayedRoutes, setDisplayedRoutes] = useState({});
  const [ports, setPorts] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [isRightPanelVisible, setIsRightPanelVisible] = useState(false);
  const [isLeftPanelVisible, setIsLeftPanelVisible] = useState(false);
  const [mode, setMode] = useState('default');
  const [currentFacilityData, setCurrentFacilityData] = useState(facilityData);
  const [showSeperateDeploymentsTable, setShowSeperateDeploymentsTable] = useState(false);
  const navigationLeftRef = useRef(null);
  const [showDeploymentsTable, setShowDeploymentsTable] = useState(false);
  const [showDatacenters, setShowDatacenters] = useState(false);
  const [showPorts, setShowPorts] = useState(false);
  const [showAirports, setShowAirports] = useState(false);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({}); 
  const [filteredFacilities, setFilteredFacilities] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [filters, setFilters] = useState({});
  const [filteredDatacenters, setFilteredDatacenters] = useState(datacentersData.features || []);

  const sortFacilities = (facilities) => {
    return facilities.sort((a, b) => 
      a.properties.FACILITY.localeCompare(b.properties.FACILITY)
    );
  };

  const processPortsAndRoutes = (data) => {
    const ports = [];
    const routes = [];
  
    // First pass: Build ports and routes arrays
    data.elements.forEach(element => {
      if (element.type === 'node') {
        ports.push({
          id: element.id,
          lat: element.lat,
          lon: element.lon,
          name: element.tags?.name || 'Unknown Port',
          hasRoutes: false
        });
      } else if (element.type === 'way' && element.nodes && element.geometry) {
        routes.push({
          id: element.nodes[0],
          nodes: element.nodes,
          geometry: element.geometry
        });
      }
    });
  
    // Second pass: Update hasRoutes for ports
    routes.forEach(route => {
      const fromPort = ports.find(p => p.id === route.id);
      if (fromPort) {
        fromPort.hasRoutes = true;
      }
      
      // Also mark other ports in the route as having routes
      route.nodes.forEach(nodeId => {
        const port = ports.find(p => p.id === nodeId);
        if (port) {
          port.hasRoutes = true;
        }
      });
    });
  
    return { ports, routes };
  };
  
  
  const messages = [
    "Carbon Footprint Reduction",
    "Labor Compliance",
    "Datacenter Relocation",
    "Energy Source Diversification",
    "Supplier Risk Assessment",
    "Water Usage Optimization",
    "..."
  ];

  const handleMessage = (event) => {
    try {
      console.log('Received message:', event.data);
      const data = event.data;

      switch (data.action) {
        case 'portClicked':
          handlePortClick(data.portId, data.portName);
          break;
        case 'loadDatacenters':
          handleButtonClick();
          break;
        case 'cesiumReady':
          if (iframeRef.current && isIframeReady) {
            iframeRef.current.contentWindow.postMessage({ action: 'preLoadTilesets' }, '*');
          }
          break;
        case 'showFacilitiesFinder':
          setIsDualPanelVisible(prev => !prev);
          if (iframeRef.current) {
            iframeRef.current.contentWindow.postMessage({ action: 'hideFacilitiesFinderController' }, '*');
          }
          break;
        case 'showFacilityInfo':
          setMode('datacenters');
          setIsDualPanelVisible(true);
          if (iframeRef.current) {
            iframeRef.current.contentWindow.postMessage({ action: 'hideFacilitiesFinderController'}, '*');
          }
          //setIsRightPanelVisible(true);
          handleMapFacilitySelect(JSON.parse(data.facility));
          break;
        default:
          break;
      }
    } catch (error) {
      console.error('Error parsing message:', error);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  useEffect(() => {
    const loadData = async () => {
      setDCData(datacentersData); 
      setIsDataLoaded(true);
    };

    loadData();

    const loadPortsData = async () => {
      setPortData(portsAndRoutesData);
      setIsPortsDataLoaded(true);
    };

    loadPortsData();

    const loadAirportsData = async () => {
      setAirportData(airportsData);
      setIsAirportsDataLoaded(true);
    };

    loadAirportsData();

    const loadPortsAndRoutesData = async () => {
      const { ports, routes } = processPortsAndRoutes(portsAndRoutesData);
      setPorts(ports);
      setRoutes(routes);
    };
  
    loadPortsAndRoutesData();
  }, []);

 

  useEffect(() => {
    if (mode === 'datacenters') {
      setFacilities(datacentersData);
    }
  }, [mode]);

  useEffect(() => {
    const baseFiltered = getFilteredFacilities(mode);
      const filtered = baseFiltered.filter((feature) => {
        return Object.entries(filters).every(([key, value]) => {
          if (!value) return true;
          const propertyValue = feature.properties[key];
          if (key === 'FACILITYOWNER' && value === '-Microsoft') {
            return propertyValue !== 'Microsoft';
          }
          return propertyValue === value;
        });
      });

      setFilteredDatacenters(filtered);
  }, [mode, filters]);

  

  const getFilteredFacilities = useMemo(() => {
    const filterAndSort = (mode) => {
      let filtered;
      switch (mode) {
        case 'hubs':
          filtered = datacentersData.features.filter(f => f.properties.FACILITYTYPE === 'Hub');
          break;
        case 'suppliers':
          filtered = datacentersData.features.filter(f => f.properties.FACILITYTYPE === 'Supplier');
          break;
        case 'datacenters':
          filtered = datacentersData.features.filter(f => f.properties.FACILITYTYPE === 'Datacenter');
          break;
        default:
          filtered = datacentersData.features.filter(f => f.properties.FACILITYTYPE === 'Datacenter');
      }
      
      return filtered.sort((a, b) => 
        a.properties.FACILITY.localeCompare(b.properties.FACILITY)
      );
    };

    const memoizedResults = {
      datacenters: null,
      hubs: null,
      suppliers: null
    };

    return (mode) => {
      if (!memoizedResults[mode]) {
        memoizedResults[mode] = filterAndSort(mode);
      }
      return memoizedResults[mode];
    };
  }, [datacentersData]);

  const handleIframeLoad = () => {
    setIsIframeReady(true);
  };

  const handleClose = () => {
    setIsRightPanelVisible(false);
    setShowDeploymentsTable(false);
    hideShipmentsRoutes();
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage({ 
        action: 'defaultMapReset',
        mapStyle: userPreferences.defaultSettings.mapDefaultStyle
      }, '*');
    }
    showDatacentersOnMap();
  }
  
  const handleButtonClick = () => {
    if (iframeRef.current && isDataLoaded && isPortsDataLoaded && isAirportsDataLoaded && isIframeReady && !showDatacenters) {
      iframeRef.current.contentWindow.postMessage({
          action: 'initLayout',
          dcData: datacentersData,
          spData: ports,
          apData: airportsData 
        }, '*');

      setShowDatacenters(true);

      setShowPorts(userPreferences.defaultSettings.showSeaports);

      setShowAirports(userPreferences.defaultSettings.showAirports);
    }
  };


  const showDatacentersOnMap = () => {
    if (iframeRef.current && isIframeReady) {
      iframeRef.current.contentWindow.postMessage({
          action: 'showDatacenters',
          dcData: datacentersData
        }, '*');
    }
  }

  const handleSelectFacilityUpcomingDeployments = (facility) => {
    setMode('datacenters');
    setIsRightPanelVisible(false);
    handleFacilitySelect(facility);
  }

  const handleFilterChange = (newFilters) => {
  
  const filtered = datacentersData.features.filter(facility => facility.properties.FACILITYTYPE === 'Datacenter').filter((datacenter) => {
      return Object.entries(newFilters).every(([key, value]) => {
        if (value === undefined) return true;
        const filterOption = filterOptions[key];
        const propertyValue = datacenter.properties[filterOption['filter-value']];
        if (key === 'owner-filter' && value === '-Microsoft') {
          return propertyValue !== 'Microsoft';
        }
        return propertyValue === value;
      });
    });
  
  const sortedFilteredDatacenters = sortFacilities(filtered);
  
  setFilteredDatacenters(sortedFilteredDatacenters);
};

  const showShipmentsRoutes = () => {
    if (iframeRef.current && isIframeReady) {
      //iframeRef.current.contentWindow.postMessage(ferryRoutesData, '*'); 
      //iframeRef.current.contentWindow.loadFerryRoutes(ferryRoutesData); 
      iframeRef.current.contentWindow.postMessage({
          action: 'showDeploymentsRoutes',
          shippingData: deploymentsData
        }, '*');
    }
  }

  const hideShipmentsRoutes = () => {
    if (iframeRef.current && isIframeReady) {
      iframeRef.current.contentWindow.postMessage({
          action: 'hideDeploymentsRoutes'
        }, '*');
    }

   
  }

  const hideShipmentsRoutesAndShowFacility = () => {
    hideShipmentsRoutes();

    zoomToCurrentFacility();
  }



  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);

    if (value) {
      const filteredSuggestions = datacentersData.features.filter(facility =>
        facility.properties.FACILITY.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (facility) => {
    setMode('datacenters');
    setIsRightPanelVisible(false);
    handleFacilitySelect(facility);
    setSearchInput('');
    setSuggestions([]);

    if (iframeRef.current) {
      const coordinates = facility.geometry.coordinates;
      iframeRef.current.contentWindow.postMessage({ coordinates }, '*'); 
      iframeRef.current.contentWindow.postMessage({ action: 'zoomTo', coordinates }, '*');
    }
  };

  const handleMapDCClick = (facility) => {
   
  } 

  const handleFacilitySelect = (facility) => {

    setSelectedFacility(facility.properties.FACILITY);
    setSelectedFacilityCoordinates(facility.geometry.coordinates);

    const newFacilityData = {
      FacilityName: facility.properties.FACILITY,
      FacilityCoordinates: { 
        long: facility.geometry.coordinates[0],
        lat: facility.geometry.coordinates[1]
      },
      FacilityAddress: {
        street: facility.properties.STREETADDRESS1 || "",
        street2: facility.properties.STREETADDRESS2 || "",
        city: facility.properties.CITY || "",
        state: facility.properties.SHIPPINGSTATE || "",
        postalCode: facility.properties.SHIPPINGZIPCODE || "",
        country: facility.properties.COUNTRY || "",
        region: facility.properties.REGION || ""
      },
      FacilityType: facility.properties.FACILITYTYPE || "",
      FacilityLocation: `${facility.properties.CITY || ""}, ${facility.properties.COUNTRY || ""}`,
      FacilityStatus: facility.properties.FACILITYPHASE || "",
      FacilityCoolingType: facility.properties.COOLING || ""
    };
  
    setCurrentFacilityData(newFacilityData);
    setIsRightPanelVisible(true);
  
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage({ 
        action: 'zoomTo', 
        coordinates: facility.geometry.coordinates,
        showCesium3D: true 
      }, '*');
    }
  };

  const handleMapFacilitySelect = (facility) => {
    setSelectedFacility(facility.properties.FACILITY);
    setSelectedFacilityCoordinates(facility.geometry.coordinates);

    const newFacilityData = {
      FacilityName: facility.properties.FACILITY,
      FacilityCoordinates: { 
        long: facility.geometry.coordinates[0],
        lat: facility.geometry.coordinates[1]
      },
      FacilityAddress: {
        street: facility.properties.STREETADDRESS1 || "",
        street2: facility.properties.STREETADDRESS2 || "",
        city: facility.properties.CITY || "",
        state: facility.properties.SHIPPINGSTATE || "",
        postalCode: facility.properties.SHIPPINGZIPCODE || "",
        country: facility.properties.COUNTRY || "",
        region: facility.properties.REGION || ""
      },
      FacilityType: facility.properties.FACILITYTYPE || "",
      FacilityLocation: `${facility.properties.CITY || ""}, ${facility.properties.COUNTRY || ""}`,
      FacilityStatus: facility.properties.FACILITYPHASE || "",
      FacilityCoolingType: facility.properties.COOLING || ""
    };
  
    setCurrentFacilityData(newFacilityData);
    setIsRightPanelVisible(true);

     if (iframeRef.current) {
        iframeRef.current.contentWindow.postMessage({ action: 'zoomTo', coordinates: facility.geometry.coordinates }, '*');
     }
  };

  const zoomToCurrentFacility = () => {

    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage({ action: 'zoomTo', coordinates: selectedFacilityCoordinates }, '*');
    }
  }

  const handleCloseDeploymentsTables = () => {
    setShowSeperateDeploymentsTable(false);
  }

  const handleDualPanelVisibility = () => {
    setIsDualPanelVisible(!isDualPanelVisible);

     if (iframeRef.current) {
      if (isDualPanelVisible) {
        iframeRef.current.contentWindow.postMessage({ action: 'showFacilitiesFinderController'}, '*');
      } else {
        iframeRef.current.contentWindow.postMessage({ action: 'hideFacilitiesFinderController'}, '*');
      }
    }
    return isDualPanelVisible;
  }

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }

  }
  const hideDualPanel = () => {
    setIsDualPanelVisible(false);
  }

  function checkInterface() {
      console.log('Checking for interface...');
      if (window.interface) {
          console.log('Interface found!');
          window.interface.MessageHandler("Hello from JavaScript!");
      } else {
          console.log('Interface not found, retrying...');
          setTimeout(checkInterface, 100); // Check again after 100ms
      }
  }

  window.addEventListener('load', handleButtonClick());

  window.addEventListener('message', function(event) {
      if (event.data.type === 'showDatacenters') {
         handleButtonClick();
      }
  });

  window.receiveMessageFromUE = function(message) {
            log('Received from Unreal: ' + message);
  };

  function log(message) {
      console.log(message);
      const logElement = document.getElementById('search-input');
      logElement.placeholder += message + '<br>';
      logElement.scrollTop = logElement.scrollHeight;
  }

  const handleUnrealLevelClick = async () => {
  
    if (window.ue && window.ue.myobject && typeof window.ue.myobject.processjsmessage === 'function') {
        const result = await window.ue.myobject.processjsmessage("load3D");
        //log('Result: ' + result);
        
    } else {
        // for debug only
        log('could not find UE interface');
    }
  };

  const handleExitClick = async () => {
    if (window.ue && window.ue.myobject && typeof window.ue.myobject.processjsmessage === 'function') {
        const result = await window.ue.myobject.processjsmessage("exit");
        //log('Result: ' + result);
        
    } else {
        // for debug only
        log('could not find UE interface');
    }
  };

  const handleSetDefaultMode = () => {
    setMode("default");
    setIsLeftPanelVisible(false);
    setIsRightPanelVisible(false);

    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage({ 
        action: 'defaultMapReset',
        mapStyle: userPreferences.defaultSettings.mapDefaultStyle
      }, '*');
    }
  }

  function resetApp() {
      setMode("default");
      setIsDualPanelVisible(false);
      setIsLeftPanelVisible(false);
      setIsRightPanelVisible(false);
      setShowDeploymentsTable(false);
      hideShipmentsRoutes();

      if (iframeRef.current) {
        iframeRef.current.contentWindow.postMessage({ action: 'showFacilitiesFinderController'}, '*');
        iframeRef.current.contentWindow.postMessage({ 
          action: 'defaultMapReset',
          mapStyle: userPreferences.defaultSettings.mapDefaultStyle
        }, '*');
      }

      showDatacentersOnMap();
  }

  window.receiveMessageFromUE = function(message) {
    console.log('Received from UE: ' + message);

    if (message === 'reset') {
      resetApp();
    }
  };

  const handlePortClick = (portId, portName) => {
    setSelectedPorts(prevPorts => {
        if (prevPorts.includes(portId)) {
            return prevPorts.filter(id => id !== portId);
        } else {
            return [...prevPorts, portId];
        }
    });

    if (displayedRoutes[portId]) {
        // remove routes if already displayed
        handleRemoveRoutes(portId);
    } else {
        // show routes not displayed
        handleShowRoutes(portId);
    }
  };

  const handleShowRoutes = (portId) => {
    if (iframeRef.current) {
        const routesFromPort = ferryRoutesData.elements.filter(element => {
          if (element.type === 'way' && element.tags.route === 'ferry') { 
            return element.nodes.includes(parseInt(portId));
          }
          return false;
        });

        iframeRef.current.contentWindow.postMessage({
            action: 'showRoutes',
            portId: portId,
            routes: routesFromPort
        }, '*');
        setDisplayedRoutes(prev => ({...prev, [portId]: routesFromPort}));
    } 
  };

  const handleRemoveRoutes = (portId) => {
      if (iframeRef.current && isIframeReady) {
          iframeRef.current.contentWindow.postMessage({
              action: 'removeRoutes',
              portId: portId
          }, '*');
          setDisplayedRoutes(prev => {
              const newDisplayed = {...prev};
              delete newDisplayed[portId];
              return newDisplayed;
          });
      }
  };

  const leftPanelContent = (
    <>
      
      {!isRightPanelVisible && (
        <div >
          <NavigationLeft
            onFacilitySelect={handleFacilitySelect}
            setMode={setMode}
            isFilterPanelOpen={isFilterPanelOpen}
            selectedMode={mode}
            handleLeftPanelVisibility={handleDualPanelVisibility}
          />
          <FilterManager
              onFilterChange={handleFilterChange}
              mode={mode}
          />
        </div>
      )};
      
      {mode === 'default' ? (
        <>
          <Box sx={{ 
            backgroundColor: 'black', 
            flex: '4 0 0', 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'flex-start',
            p: 2,
          }}>
            <Typography variant="h4" sx={{ fontSize: '30px', mb: 2, color: 'white' }}>
              Upcoming Deployments
            </Typography>
            <DeploymentsTable 
            onFacilitySelect={handleSelectFacilityUpcomingDeployments}
            selectedFacility={selectedFacility}
            sx={{backgroundColor: '#292930'}}/>
          </Box>
  
          <Box sx={{ 
            backgroundColor: 'black', 
            flex: '3 0 0', 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'flex-start',
            p: 2,
          }}>
            <Typography variant="h4" sx={{ fontSize: '30px', mb: 2, color: 'white' }}>
              Global Operational KPIs
            </Typography>
            <GlobalKPIs />
          </Box>
  
          <Box sx={{ 
            backgroundColor: 'black', 
            flex: '3 0 0', 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'flex-start',
            p: 2,
          }}>
            <Typography variant="h4" sx={{ fontSize: '30px', mb: 2, color: 'white'}}>
              Prompts
            </Typography>
            <Prompts messages={messages}/>
          </Box>
        </>
      ) : (
        <FacilityFinder
          datacentersData={filteredDatacenters}
          onFacilitySelect={handleFacilitySelect}
          selectedFacility={selectedFacility}
          leftPanelRef={navigationLeftRef}
          rightPanelStatus={isRightPanelVisible}
          mode={mode}
          onClose={handleSetDefaultMode}
        />
      )}
    </>
  );

  return (
    <LoadingOverlay
            active={isLoading}
            spinner
            text="Loading..."
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(0, 0, 0, 0.8)', 
                }),
                content: (base) => ({
                    ...base,
                    color: '#fff', // Text color
                }),
            }}
        >
    <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'black', minWidth: '1024px', 
        minHeight: '768px',
        width: '100%',
        height: '100vh',
        overflow: 'auto'}}>
      <AppBar position="static" color="default" elevation={0} sx={{ backgroundColor: 'black' }} >
        <Toolbar>
          <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center', mr: 1}}>
            <img src="./images/msn-logo-transparent.png" alt="Logo" style={{ height: '25px' }} />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 0, display: { xs: 'none', sm: 'block' }, color: 'lightgray', fontSize: '19px', marginRight: '10px' }}
          >
            Microsoft SCN
          </Typography>
          <Box sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            alignItems: 'center', 
            backgroundColor: '#141314',
            borderRadius: 10, 
            pl: 2,
            mr: 2,
            maxWidth: '420px',
            position: 'relative',
            border: '1px solid white', 
        }}>
            <InputBase
                id="search-input"
                placeholder="Find Data centers, Hubs, or Suppliers"
                value={searchInput}
                onChange={handleSearchChange}
                sx={{ 
                    color: '#6A6A6A', 
                    width: '97%',
                    height: '40px',
                    backgroundColor: '#141314', 
                    borderRadius: '4px', 
                    padding: '8px 40px 8px 8px',
                    '& .MuiInputBase-input': {
                        padding: '8px 0',
                        '&::placeholder': {
                            color: 'lightblue', 
                        },
                    },
                }}
            />
            <SearchIcon sx={{ 
                color: '#BFECFF', 
                position: 'absolute', 
                right: '10px', 
                top: '50%', 
                transform: 'translateY(-50%)' 
            }} />
            {suggestions.length > 0 && (
                <List sx={{ 
                    position: 'absolute', 
                    zIndex: 1001, 
                    backgroundColor: 'white', 
                    maxHeight: '200px', 
                    overflowY: 'auto', 
                    width: '98%', 
                    top: 'calc(100% + 2px)',
                    padding: 0, 
                    border: '1px solid #ccc', 
                    borderRadius: '4px' 
                }}>
                    {suggestions.map((facility, index) => (
                        <ListItem button key={index} onClick={() => handleSuggestionClick(facility)}>
                            {facility.properties.FACILITY}
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={() => handleExitClick()}
            sx={{ mr: 1, backgroundColor: '#BFECFF', color: 'black'}}
          >
            Exit
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.reload();
            }}
            sx={{ mr: 1, backgroundColor: '#BFECFF', color: 'black'}}
          >
            reset
          </Button>
          
          
          
          <Box sx={{ flexGrow: 1 }} />

          
          <Box sx={{ marginRight: '35px' }}>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationIcon  sx={{ fontSize: '30px', color: '#BFECFF' }}/>
              </Badge>
            </IconButton>
          </Box>
          
          <UserProfilePanel handleSignOut={handleLogout} datacentersData={filteredDatacenters}/>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
        {isDualPanelVisible && (
        <DualPanel
          isVisible={true}
          refPass={navigationLeftRef}
          leftPanelContent={leftPanelContent}
          rightPanelContent={
            <DatacenterInfo 
            geolocation={[currentFacilityData.FacilityCoordinates.long, currentFacilityData.FacilityCoordinates.lat]}
            onClose={handleClose}
            facilityData={currentFacilityData}
            setShowSeperateDeploymentsTable={setShowDeploymentsTable}
            switchToUE={handleUnrealLevelClick}
            mode={mode}
          />
          }
          isRightPanelVisible={isRightPanelVisible}
          setIsRightPanelVisible={setIsRightPanelVisible}
          facilityFinderComponent={
            <FacilityFinder
              datacentersData={datacentersData}
              onFacilitySelect={handleFacilitySelect}
              selectedFacility={selectedFacility}
              leftPanelRef={navigationLeftRef}
              rightPanelStatus={isRightPanelVisible}
              onClose={handleSetDefaultMode}
            />
          }
        />
        )}
        
        <Box sx={{ 
          flexGrow: 1,
          backgroundColor: 'black', 
          padding: 0, 
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <iframe
            ref={iframeRef}
            src="azuremaps.html"
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Embedded Map Page"
            onLoad={handleIframeLoad}
            allow="cross-origin-isolated"
          ></iframe>
        </Box>
      </Box>
      {showDeploymentsTable && currentFacilityData && (
            <Box 
              sx={{ 
                position: 'absolute', 
                width: 'calc(100% - 670px)', 
                height: '400px',
                marginLeft: "auto", 
                backgroundColor: '#333333',
                zIndex: 1001,
                right:0, bottom:0, overflow: 'hidden'
              }}
            >
              <DatacenterDeployments 
                facilityData={currentFacilityData}
                onClose={() => setShowDeploymentsTable(false)}
                showShipmentsRoutes={showShipmentsRoutes}
                hideShipmentsRoutes={hideShipmentsRoutesAndShowFacility}
              />
            </Box>
          )}

    </Box>
    </LoadingOverlay>
  );
};
