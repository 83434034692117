import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import { AzureMapsProvider, AzureMap, AzureMapHtmlMarker } from 'react-azure-maps';
import { AuthenticationType } from 'azure-maps-control';
import CloseIcon from '@mui/icons-material/Close';
import 'azure-maps-control/dist/atlas.min.css';

const DatacenterInfo = ({ geolocation, onClose, facilityData, setShowSeperateDeploymentsTable, switchToUE, mode }) => {
    const mapRef = useRef(null);
    const [isMapReady, setIsMapReady] = useState(false);
    const subscriptionApiKey = process.env.REACT_APP_AZURE_API_KEY

    useEffect(() => {
        if (mapRef.current && isMapReady) {
            mapRef.current.setCamera({
                center: geolocation,
                zoom: 17
            });
        }
    }, [geolocation, isMapReady]);

    const onMapLoad = () => {
        setIsMapReady(true);
    };

    const kpiData = [
        { status: 'yellow', description: 'Power Capacity', kpi: '60,000 kW' },
        { status: 'green', description: 'Utilization Rate', kpi: '86%' },
        { status: 'red', description: 'Stranded Power', kpi: '8,400 kW' },
        { status: 'yellow', description: 'Carbon Footprint', kpi: '1000 kgCO2/h' },
        { status: 'green', description: 'Land Impact', kpi: '200k sq ft' },
    ];

    const kpiDataHubs = [
        { status: 'yellow', description: 'Inventory Turns', kpi: '2.7' },
        { status: 'green', description: '% of aging Inventory', kpi: '18%' },
        { status: 'red', description: 'Hub Cycle Time', kpi: '6.8 days' },
        { status: 'yellow', description: 'Carbon Footprint', kpi: '1000 kgCO2/h' },
        { status: 'green', description: 'Land Impact', kpi: '200k sq ft' },
    ];

    const kpiDataSuppliers = [
        { status: 'yellow', description: 'Number of Racks Built', kpi: '2.5k in August' },
        { status: 'red', description: 'Deployment Readiness by PDD Date', kpi: '94%%' },
        { status: 'green', description: 'Carbon Footprint', kpi: '1000 kgCO2/h' },
        { status: 'green', description: 'Land Impact', kpi: '200k sq ft' },
    ];

    const getStatusColor = (status) => {
        switch (status) {
            case 'red': return 'rgb(255, 0, 0)';
            case 'yellow': return 'rgb(255, 255, 0)';
            case 'green': return 'rgb(0, 255, 0)';
            default: return 'rgb(128, 128, 128)';
        }
    };

    const getLightStatusColor = (status) => {
        switch (status) {
            case 'red': return 'rgba(255, 0, 0, 0.1)';
            case 'yellow': return 'rgba(255, 255, 0, 0.1)';
            case 'green': return 'rgba(0, 255, 0, 0.1)';
            default: return 'rgba(128, 128, 128, 0.1)';
        }
    };

    const mapOptions = {
        center: geolocation,
        zoom: 17,
        authOptions: {
            authType: AuthenticationType.subscriptionKey,
            subscriptionKey:subscriptionApiKey 
        },
        style: 'satellite_road_labels',
        renderWorldCopies: false
    };

    const renderInfoTable = (headers, data) => (
        <TableContainer sx={{ mb: 2, backgroundColor: 'transparent' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {headers.map((header, index) => (
                            <TableCell key={index} sx={{ color: 'white', fontWeight: 'bold', border: 'none', padding: '4px' }}>{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {data.map((value, index) => (
                            <TableCell key={index} sx={{ color: 'white', border: 'none', padding: '4px' }}>{value}</TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );

    const getDCRows = () => {
        <TableContainer sx={{ width: '100%', boxShadow: 'none', backgroundColor: '#292930', mt: 2 }}>
        <Table sx={{ 
            width: '100%', 
            backgroundColor: 'transparent', 
            borderCollapse: 'separate',
            borderSpacing: '0 2px',
        }}>
            <TableBody>
                {kpiData.map((row, index) => (
                <TableRow key={index} sx={{ 
                    backgroundColor: getLightStatusColor(row.status),
                    height: '30px',
                }}>
                    <TableCell sx={{ width: '5px', padding: 0 }}>
                        <Box sx={{ 
                            backgroundColor: getStatusColor(row.status),
                            width: '100%',
                            height: '100%',
                        }} />
                    </TableCell>
                    <TableCell sx={{ width: '47.5%', color: 'white', fontSize: '14px', padding: '4px' }}>
                        {row.description}
                    </TableCell>
                    <TableCell sx={{ width: '47.5%', color: 'white', fontSize: '14px', textAlign: 'right', padding: '4px' }}>
                        {row.kpi}
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    }

    const getKPIData = () => {
        switch (mode) {
            case 'datacenters':
            return kpiData;
            case 'hubs':
            return kpiDataHubs;
            case 'suppliers':
            return kpiDataSuppliers;
            default:
            return kpiData;
        }
    };

    const getBMetricsTable_ = () => {
        const data = getKPIData();
        return (
            <Table sx={{ 
                width: '100%', 
                backgroundColor: 'transparent', 
                borderCollapse: 'separate',
                borderSpacing: '0 2px',
            }}>
            <TableBody>
            {data.map((row, index) => (
                <TableRow key={index} sx={{ backgroundColor: getLightStatusColor(row.status) }}>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.kpi}</TableCell>
                <TableCell>
                    <Box
                    sx={{
                        width: 20,
                        height: 20,
                        borderRadius: '50%',
                        backgroundColor: getStatusColor(row.status)
                    }}
                    />
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
            </Table>
        );
    };

     const getBMetricsTable = () => {
        const data = getKPIData();
        return (
            <TableContainer sx={{ width: '100%', boxShadow: 'none', backgroundColor: '#292930', mt: 2 }}>
            <Table sx={{ 
                width: '100%', 
                backgroundColor: 'transparent', 
                borderCollapse: 'separate',
                borderSpacing: '0 2px',
            }}>
                <TableBody>
                    {data.map((row, index) => (
                    <TableRow key={index} sx={{ 
                        backgroundColor: getLightStatusColor(row.status),
                        height: '30px',
                    }}>
                        <TableCell sx={{ width: '5px', padding: 0 }}>
                            <Box sx={{ 
                                backgroundColor: getStatusColor(row.status),
                                width: '100%',
                                height: '100%',
                            }} />
                        </TableCell>
                        <TableCell sx={{ width: '47.5%', color: 'white', fontSize: '14px', padding: '4px' }}>
                            {row.description}
                        </TableCell>
                        <TableCell sx={{ width: '47.5%', color: 'white', fontSize: '14px', textAlign: 'right', padding: '4px' }}>
                            {row.kpi}
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
        );
    };

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: '#333333' }}>
            <Box sx={{ width: '100%', height: '300px', padding: 0, margin: 0, position: 'relative' }}>
                <AzureMapsProvider>
                    <AzureMap 
                        ref={mapRef}
                        options={mapOptions} 
                        style={{ width: '100%', height: '100%' }}
                        events={{
                            ready: onMapLoad,
                        }}
                    >
                        <AzureMapHtmlMarker options={{ position: geolocation }} />
                    </AzureMap>
                </AzureMapsProvider>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: '#333333',
                        color: 'white',
                        borderRadius: '50%',
                        padding: 1,
                        '&:hover': {
                            backgroundColor: '#444444',
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box sx={{ padding: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h4" sx={{ fontSize: '33px', mb: 0, color: 'white' }}>
                    {facilityData.FacilityName}
                </Typography>
                <Box sx={{ mt: 1.0, mb: 2 }}>
                    <Typography variant="body1" sx={{ color: 'white', lineHeight: 1.2 }}>
                        {`${facilityData.FacilityAddress.street}, ${facilityData.FacilityAddress.city}, ${facilityData.FacilityAddress.state} ${facilityData.FacilityAddress.postalCode}`}
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'white', lineHeight: 1.2 }}>
                        {`${facilityData.FacilityAddress.country}, ${facilityData.FacilityAddress.region}`}
                    </Typography>
                </Box>
                {mode === 'datacenters' && (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
                        <Button variant="contained" color="primary" onClick={() => setShowSeperateDeploymentsTable(true)}>Deployments</Button>
                        <Button variant="contained" color="secondary" onClick={switchToUE}>3D View</Button>
                    </Box>
                </>
                )}
                
                {renderInfoTable(
                    ['Type', 'Location', 'Status'],
                    [facilityData.FacilityType, facilityData.FacilityLocation, facilityData.FacilityStatus]
                )}
                {renderInfoTable(
                    ['Coordinates'],
                    [`${facilityData.FacilityCoordinates.lat}, ${facilityData.FacilityCoordinates.long}`]
                )}
                {renderInfoTable(
                    ['Cooling Type'],
                    [facilityData.FacilityCoolingType]
                )}
                <Typography variant="h4" sx={{ fontSize: '33px', mb: 2, color: 'white' }}>
                    Building Metrics
                </Typography>
               {getBMetricsTable()} 
            </Box>
        </Box>
    );
};

export default DatacenterInfo;