import React, { useState, useEffect } from 'react';
import { Box, Typography, Tabs, Tab, Select, MenuItem, FormControl, InputLabel, IconButton, Button, Modal, Divider, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import ShippingList from './ShippingInfo';
import DeliveriesInfo from './DeliveriesInfo';
import deploymentsData from '../data/deployments.json';
import deliveriesData from '../data/deliveries.json';

const DatacenterDeployments = ({ onClose, showShipmentsRoutes, hideShipmentsRoutes}) => {
    const [tabValue, setTabValue] = useState(0);
    const [filter, setFilter] = useState('all');
    const [selectedDeployment, setSelectedDeployment] = useState('all');
    const [filteredShipments, setFilteredShipments] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalStep, setModalStep] = useState(1);
    const [selectedAction, setSelectedAction] = useState('');
    const [isDeliveriesTab, setIsDeliveriesTab] = useState(false);
    const [deliveries, setDeliveries] = useState([]);



    const deployments = deploymentsData.datacenter.deployments;

    useEffect(() => {
        const allShipments = deployments.flatMap(d => d.shipments);
        setFilteredShipments(allShipments);

        setDeliveries(deliveriesData.datacenter.deliveries);
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setIsDeliveriesTab(!isDeliveriesTab);

        if (!isDeliveriesTab) {
            showShipmentsRoutes();
        } else {
            hideShipmentsRoutes();
        }

    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
        const allShipments = deployments.flatMap(d => d.shipments);
        if (event.target.value === 'all') {
            setFilteredShipments(allShipments);
        } else {
            setFilteredShipments(allShipments.filter(s => s.status === event.target.value));
        }
    };

    const handleDeploymentChange = (event) => {
        setSelectedDeployment(event.target.value);
        if (event.target.value === 'all') {
            const allShipments = deployments.flatMap(d => d.shipments);
            setFilteredShipments(allShipments);
        } else {
            const selectedDeploymentShipments = deployments.find(d => d.id === event.target.value)?.shipments || [];
            setFilteredShipments(selectedDeploymentShipments);
        }
    };

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => {
        setModalOpen(false);
        setModalStep(1);
    };

    const handleActionSelect = (action) => {
        setSelectedAction(action);
        setModalStep(3);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: 'calc(50% + 345px)', 
        transform: 'translate(-50%, calc(-50% - 100px))',
        width: 400,
        bgcolor: 'grey',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.25)',
        p: 4,
        textAlign: 'center',
    };

    const buttonStyle = {
        mr: 1,
        backgroundColor: '#BFECFF',
        color: 'black',
        '&:hover': {
            backgroundColor: '#9FD5FF',
        }
    };

    const exitLinkStyle = {
        ml: 1,
        color: 'black',
        fontSize: '16px',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        }
    };

    const renderModalContent = () => {
        switch (modalStep) {
            case 1:
                return (
                    <Box>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Delivery Delay Notice</Typography>
                        <Typography variant="body1">
                            Shipment ID: 12345<br />
                            Destination: New York<br />
                            Original ETA: 2023-06-01<br />
                            New ETA: 2023-06-05
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                            <Button sx={buttonStyle} onClick={() => setModalStep(2)}>Re-Evaluate Deliveries</Button>
                            <Link
                                component="button"
                                onClick={handleModalClose}
                                sx={exitLinkStyle}
                            >
                                Exit
                            </Link>
                        </Box>
                    </Box>
                );
            case 2:
                return (
                    <Box>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Recommended</Typography>
                        <Button sx={{...buttonStyle, backgroundColor: 'green', color: 'white', width: '100%', mb: 2}} onClick={() => handleActionSelect('Reroute Shipment')}>
                            Reroute Shipment
                        </Button>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Other Options</Typography>
                        {['Delay Shipment', 'Re-Source Shipment', 'Swap Shipment'].map((action) => (
                            <Button key={action} sx={{...buttonStyle, backgroundColor: 'green', color: 'white', display: 'block', width: '100%', mb: 1}} onClick={() => handleActionSelect(action)}>
                                {action}
                            </Button>
                        ))}
                        <Button sx={{...buttonStyle, backgroundColor: 'red', color: 'white', display: 'block', width: '100%'}} onClick={() => handleActionSelect('Cancel Shipment')}>
                            Cancel Shipment
                        </Button>
                    </Box>
                );
            case 3:
                return (
                    <Box>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>{selectedAction}</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            Are you sure you want to {selectedAction.toLowerCase()}?
                        </Typography>
                        <Box>
                            <Button sx={{...buttonStyle, backgroundColor: 'red', color: 'white', mr: 1}} onClick={handleModalClose}>Confirm</Button>
                            <Button sx={{...buttonStyle, backgroundColor: 'green', color: 'white'}} onClick={() => setModalStep(2)}>Back</Button>
                            <Link
                                component="button"
                                onClick={handleModalClose}
                                sx={exitLinkStyle}
                            >
                                Exit
                            </Link>
                        </Box>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ 
            width: '100%', 
            height: '100%', 
            display: 'flex', 
            flexDirection: 'column', 
            backgroundColor: '#1e1e1e', 
            overflow: 'hidden'
        }}>
            <Box sx={{ padding: '8px 16px', backgroundColor: '#333333' }}>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 4,
                        right: 4,
                        padding: '4px',
                        backgroundColor: '#444444',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#555555',
                        },
                    }}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>

                <Typography variant="h5" sx={{ fontSize: '24px', mb: 1, color: 'white', paddingRight: '40px' }}>
                    Shipments & Deliveries
                </Typography>
                
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} sx={{ minHeight: '36px' }}>
                        <Tab label="Shipments" sx={{ color: 'white', minHeight: '36px', padding: '6px 12px' }} />
                        <Tab label="Deliveries" sx={{ color: 'white', minHeight: '36px', padding: '6px 12px' }} />
                    </Tabs>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            startIcon={<WarningIcon />}
                            onClick={handleModalOpen}
                            sx={{
                                mr: 2,
                                backgroundColor: '#BFECFF',
                                color: 'black',
                                '&:hover': {
                                    backgroundColor: '#9FD5FF',
                                },
                                height: '36px',
                            }}
                        >
                            Notifications
                        </Button>
                        <FormControl sx={{ width: '190px', mr: 2 }} size="small">
                            <InputLabel id="deployment-label" sx={{ color: 'white' }}>Select Deployment</InputLabel>
                            <Select
                                labelId="deployment-label"
                                value={selectedDeployment}
                                onChange={handleDeploymentChange}
                                sx={{
                                    color: 'white',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    },
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {deployments.map((deployment) => (
                                    <MenuItem key={deployment.id} value={deployment.id}>{deployment.id}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        
                        <FormControl sx={{ width: '120px' }} size="small">
                            <InputLabel id="filter-label" sx={{ color: 'white' }}>Filter</InputLabel>
                            <Select
                                labelId="filter-label"
                                value={filter}
                                onChange={handleFilterChange}
                                sx={{
                                    color: 'white',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    },
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="ontime">On Time</MenuItem>
                                <MenuItem value="delayed">Delayed</MenuItem>
                                <MenuItem value="arrived">Arrived</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            
            <Box sx={{ flexGrow: 1, overflow: 'auto', pl: 2, pr: 0, py: 1, height: 'calc(100% - 100px)' }}>
                {tabValue === 0 && <ShippingList shipments={filteredShipments} />}
                {tabValue === 1 && <DeliveriesInfo deliveries={deliveries} />}
            </Box>

            <Modal
                open={modalOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleModalClose();
                    }
                }}
                disableEscapeKeyDown
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <IconButton
                        onClick={handleModalClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'black',
                            backgroundColor: 'white',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                            },
                            borderRadius: '50%',
                            padding: '4px',
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                    {renderModalContent()}
                </Box>
            </Modal>
        </Box>
    );
};

export default DatacenterDeployments;