import React, { useState, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import "../css/DualPanel.css";

const DualPanel = ({
  isVisible,
  leftPanelContent,
  rightPanelContent,
  isRightPanelVisible,
  setIsRightPanelVisible,
  refPass,
  facilityFinderComponent,
}) => {
  const leftPanelWidth = 670;
  const rightPanelWidth = 700; 
  const facilityFinderWidth = 275;
  const sliderWidth = 20;

  const [sliderPosition, setSliderPosition] = useState(0);
  const containerRef = useRef(null);
  const sliderRef = useRef(null);
  const isDragging = useRef(false);

  useEffect(() => {
    if (isRightPanelVisible) {
      setSliderPosition(0);
    }
  }, [isRightPanelVisible]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging.current) return;
      const containerRect = containerRef.current.getBoundingClientRect();
      const newPosition = e.clientX - containerRect.right + rightPanelWidth;
      setSliderPosition(Math.max(0, Math.min(newPosition, rightPanelWidth)));
    };

    const handleMouseUp = () => {
      isDragging.current = false;
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const handleMouseDown = () => {
    isDragging.current = true;
  };

  if (!isVisible) return null;

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      
      <div
        className="dual-panel-container"
        ref={containerRef}
        style={{ width: `${isRightPanelVisible ? facilityFinderWidth : leftPanelWidth}px`, height: "100%" }}
      >
        <div
          className="panel left-panel"
          style={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
          ref={refPass}
        >
          {leftPanelContent}
        </div>
      </div>
      {isRightPanelVisible && (
        <>
          {/* <div
            className="slider"
            style={{
              right: `${rightPanelWidth - sliderPosition}px`,
              width: `${sliderWidth}px`,
              position: "absolute",
              top: 0,
              bottom: 0,
              cursor: "ew-resize",
              backgroundColor: "#444",
              zIndex: 1001,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onMouseDown={handleMouseDown}
            ref={sliderRef}
          >
            <div
              style={{
                width: "6px",
                height: "30px",
                backgroundColor: "white",
                borderRadius: "3px",
              }}
            ></div>
          </div> 
          <div
            className="panel right-panel"
            style={{
              width: `${rightPanelWidth - sliderPosition}px`,
              position: "absolute",
              right: 0,
              top: 0,
              height: "100%",
              backgroundColor: "white",
              boxShadow: "-2px 0 5px rgba(0,0,0,0.1)",
              zIndex: 1000,
              display: "flex",
            }}
          > 
           <div
              style={{
                width: `${facilityFinderWidth}px`,
                height: "100%",
                overflowY: "auto",
                borderRight: "1px solid #ccc",
              }}
            >
              {facilityFinderComponent}
            </div> */}
            <div
              style={{
                width: `${leftPanelWidth - facilityFinderWidth}px`,
                height: "100%",
                overflow: "auto",
              }}
            >
              {rightPanelContent}
            </div>
          {/* </div> */}
        </>
      )}
    </Box>
  );
};

export default DualPanel;
