import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, ListItem } from '@mui/material';

const createData = (datacenter, supplier, origin, requested, scheduled, status, priority, facilityObject) => {
  return { datacenter, supplier, origin, requested, scheduled, status, priority, facilityObject };
};

const facilityObjects = {
  'Quincy MWH06': {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [-119.8828, 47.23342]
    },
    "properties": {
      "FACILITY": "Quincy MWH06",
      "FACILITYSHORTCODE": "MWH06",
      "DCOPSREGION": "US West",
      "CITY": "Quincy",
      "SHIPPINGSTATE": "WA",
      "SHIPPINGCITY": "Quincy",
      "SHIPPINGADDRESS": "1515 Port Industrial Way",
      "SHIPPINGZIPCODE": "98848-5002",
      "COUNTRY": "United States",
      "EntityType": "maybeLate"
    }
  },
  'Boston BOS32': {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [-71.219038, 42.301218]
    },
    "properties": {
      "FACILITY": "Boston BOS32",
      "FACILITYSHORTCODE": "BOS32",
      "DCOPSREGION": "US East",
      "CITY": "NEEDHAM HEIGHTS",
      "SHIPPINGSTATE": "MA",
      "SHIPPINGCITY": "Needham Heights",
      "SHIPPINGADDRESS": "128 1ST AVE",
      "SHIPPINGZIPCODE": "02494-2805",
      "COUNTRY": "United States",
      "EntityType": "defLate"
    }
  }
};

const rows = [
  createData('Quincy MWH06', 'Micron', 'USA', '05/10/2024', '08/12/2024', 'Delayed', '1', facilityObjects['Quincy MWH06']),
  createData('Boston BOS32', 'Samsung', 'S. Korea', '06/11/2024', '08/13/2024', 'On Time', '2', facilityObjects['Boston BOS32']),
];

const getStatusColor = (status) => {
  switch (status) {
    case 'Delayed':
      return 'darkred'; 
    case 'Risk Delay':
      return 'yellow'; 
    case 'Risk/Delay':
      return 'darkorange'; 
    case 'On Time':
    case 'Ready':
      return 'darkgreen'; 
    default:
      return 'transparent';
  }
};

const createSubTableData = (sku, item, qty, status) => {
  return { sku, item, qty, status };
};

const getSubTableRows = (rowCount, mainTableStatus) => {
  const items = ['Rack', 'Network Switch', 'Router', 'CPU', 'GPU', 'Motherboard', 'Memory', 'Power Supply'];
  const rows = [];
  const usedItems = new Set();

  for (let i = 0; i < rowCount; i++) {
    let status;
    if (mainTableStatus === 'On Time') {
      status = 'Ready';
    } else if (mainTableStatus === 'Delayed') {
      status = i === 0 ? 'Delayed' : (Math.random() < 0.7 ? 'Ready' : 'Risk Delay');
    } else {
      status = Math.random() < 0.7 ? 'Ready' : 'Risk Delay';
    }

    let item;
    do {
      item = items[Math.floor(Math.random() * items.length)];
    } while (usedItems.has(item));
    usedItems.add(item);

    rows.push(createSubTableData(
      `SKU${Math.floor(Math.random() * 1000)}${String.fromCharCode(65 + Math.floor(Math.random() * 26))}`,
      item,
      Math.floor(Math.random() * 100) + 1,
      status
    ));
  }
  return rows;
};

const SubTable = ({ rowCount, mainTableStatus }) => {
  const subTableRows = getSubTableRows(rowCount, mainTableStatus);
  return (
    <TableContainer component={Paper} sx={{ backgroundColor: '#1E1E24', mt: 1 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: '12px' }}>SKU</TableCell>
            <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: '12px' }}>Item</TableCell>
            <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: '12px' }}>Qty</TableCell>
            <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: '12px' }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subTableRows.map((row, index) => (
            <TableRow key={index}>
              <TableCell sx={{ color: 'white', fontSize: '11px' }}>{row.sku}</TableCell>
              <TableCell sx={{ color: 'white', fontSize: '11px' }}>{row.item}</TableCell>
              <TableCell sx={{ color: 'white', fontSize: '11px' }}>{row.qty}</TableCell>
              <TableCell sx={{ color: 'white', fontSize: '11px' }}>
                <Box sx={{ 
                  backgroundColor: getStatusColor(row.status),
                  padding: '2px 4px',
                  width: 'fit-content',
                  borderRadius: '4px',
                  color: row.status === 'Risk Delay' ? 'black' : 'white',
                }}>
                  {row.status}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const DeploymentsTable = ({ onFacilitySelect, selectedFacility }) => {
  const subTableRowCounts = [3, 1];

  return (
    <Box sx={{ width: '100%'}}>
      {rows.map((row, index) => (
        <TableContainer
          key={index}
          component={Paper}
          sx={{
            width: '100%',
            boxShadow: 'none',
            overflow: 'hidden',
            mb: 2, 
            borderBottom: '1px solid black', 
            backgroundColor: '#292930'
          }}
        >
          <Table stickyHeader aria-label="simple table" sx={{ 
            '& .MuiTableCell-root': { border: 'none', padding: '2px 4px', backgroundColor: '#292930'}, 
            'border-spacing': '0 5px', 
            'border-collapse': 'separate'
          }}>
            <TableHead sx={{ backgroundColor: '#292930' }}>
              <TableRow>
                <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'left', fontSize: '14px', width: '35%' }} colSpan={3}>Data Center</TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'left', fontSize: '14px', width: '10%' }}>Supplier</TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'left', fontSize: '14px', width: '13%' }}>Origin</TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'left', fontSize: '14px' }}>Requested</TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'left', fontSize: '14px' }}>Scheduled</TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center', fontSize: '14px', width: '15%' }}>Status</TableCell> 
                <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center', fontSize: '14px', width: '5%' }}>Priority</TableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} sx={{ padding: 0, width: '35%', verticalAlign: 'middle' }}>
                  <ListItem
                    button
                    onClick={() => onFacilitySelect(row.facilityObject)}
                    sx={{
                      color: "black",
                      backgroundColor: "#BFECFF",
                      borderRadius: "15px",
                      padding: "4px 8px",
                      fontSize: "11px",
                      "&:hover": {
                        backgroundColor: "#BFECFF",
                        color: "black",
                      },
                    }}
                  >
                    {row.datacenter}
                  </ListItem>
                </TableCell>
                <TableCell sx={{ color:'white', border: 'none', fontSize: '12px', width: '10%', verticalAlign: 'middle' }}>{row.supplier}</TableCell>
                <TableCell sx={{ color:'white', border: 'none', fontSize: '12px', width: '13%', verticalAlign: 'middle' }}>{row.origin}</TableCell>
                <TableCell sx={{ color:'white', border: 'none', fontSize: '12px', verticalAlign: 'middle' }}>{row.requested}</TableCell>
                <TableCell sx={{ color:'white', border: 'none', fontSize: '12px', verticalAlign: 'middle' }}>{row.scheduled}</TableCell>
                <TableCell sx={{ color:'white', border: 'none', fontSize: '12px', textAlign: 'center', verticalAlign: 'middle' }}>
                  <Box sx={{ 
                    backgroundColor: getStatusColor(row.status),
                    padding: '2px 4px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                    {row.status}
                  </Box>
                </TableCell>
                <TableCell sx={{ color:'white', border: 'none', fontSize: '12px', textAlign: 'center', verticalAlign: 'middle' }}>{row.priority}</TableCell> 
              </TableRow>
              <TableRow>
                <TableCell colSpan={9}>
                  <SubTable rowCount={subTableRowCounts[index]} mainTableStatus={row.status} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </Box>
  );
};

export default DeploymentsTable;