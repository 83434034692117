import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Collapse,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

const FacilityFinder = ({
  datacentersData,
  onFacilitySelect,
  selectedFacility,
  leftPanelRef,
  rightPanelStatus,
  mode,
  onClose,
}) => {
  const [facilities, setFacilities] = useState([]);
  const [openGroups, setOpenGroups] = useState({});
  const listRef = useRef(null);

  useEffect(() => {
    const filteredData = datacentersData.filter(facility => {
      if (mode === 'datacenters') {
        return facility.properties.FACILITYTYPE === 'Datacenter';
      } else if (mode === 'hubs') {
        return facility.properties.FACILITYTYPE === 'Hub';
      } else if (mode === 'suppliers') {
        return facility.properties.FACILITYTYPE === 'Supplier';
      }
      return false;
    });

    const groupedFacilities = filteredData.reduce((acc, facility) => {
      const groupName = facility.properties.FACILITYGROUPNAME;
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(facility);
      return acc;
    }, {});
    setFacilities(groupedFacilities);
  }, [datacentersData, mode]);

  const handleFacilityClick = (facility) => {
    onFacilitySelect(facility);
  };

  const handleGroupToggle = (groupName) => {
    setOpenGroups((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  return (
    <Box
      sx={{
        backgroundColor: "black",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        p: 2,
        overflowY: "auto",
      }}
      ref={listRef}
    >
      <Typography variant="h4" sx={{ fontSize: "30px", mb: 2, color: "white" }}>
        {mode === 'hubs' ? 'Hubs' : mode === 'suppliers' ? 'Suppliers' : 'Data Centers'}
      </Typography>
      {!rightPanelStatus && (
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 90,
            right: 10,
            backgroundColor: "lightblack",
            color: "white",
            borderRadius: "50%",
            padding: 1,
            "&:hover": {
              backgroundColor: "#444444",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <List sx={{ width: "100%", color: "white", "& .MuiListItem-root": { py: 0.2 } }}>
        {Object.entries(facilities).map(([groupName, facilities]) => {
          if (facilities.length === 1) {
            // Display single facility without group, aligned with grouped entries
            const facility = facilities[0];
            return (
              <ListItem
                key={facility.properties.FACILITY}
                button
                onClick={() => handleFacilityClick(facility)}
                selected={
                  selectedFacility &&
                  selectedFacility.properties &&
                  facility.properties &&
                  selectedFacility.properties.FACILITY === facility.properties.FACILITY
                }
                sx={{
                  pl: 1, // Align with grouped entries
                  "&.Mui-selected": {
                    backgroundColor: "#333333",
                    borderRadius: "8px",
                  },
                  "&:hover": {
                    color: 'black',
                    backgroundColor: "#BFECFF",
                    borderRadius: "8px",
                  },
                }}
              >
                <ListItemText
                  primary={facility.properties.FACILITY}
                  secondary={
                    `${facility.properties.CITY || "Unknown City"}, ${
                      facility.properties.COUNTRY || "Unknown Country"
                    }`
                  }
                  secondaryTypographyProps={{ color: "gray", fontSize: '0.75rem' }}
                />
              </ListItem>
            );
          } else {
            // Display group with expandable list
            return (
              <React.Fragment key={groupName}>
                <ListItem button onClick={() => handleGroupToggle(groupName)} sx={{ pl: 0 }}>
                  {openGroups[groupName] ? <ArrowDropDown /> : <ArrowRight />}
                  <ListItemText
                    primary={`${groupName} (${facilities.length})`}
                    sx={{ color: "white", ml: 0.3 }}
                  />
                </ListItem>
                <Collapse in={openGroups[groupName]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {facilities.map((facility, index) => (
                      <ListItem
                        key={index}
                        button
                        onClick={() => handleFacilityClick(facility)}
                        selected={
                          selectedFacility &&
                          selectedFacility.properties &&
                          facility.properties &&
                          selectedFacility.properties.FACILITY === facility.properties.FACILITY
                        }
                        sx={{
                          pl: 3,
                          "&.Mui-selected": {
                            backgroundColor: "#333333",
                            borderRadius: "8px",
                          },
                          "&:hover": {
                            color: 'black',
                            backgroundColor: "#BFECFF",
                            borderRadius: "8px",
                          },
                        }}
                      >
                        <ListItemText
                          primary={facility.properties.FACILITY}
                          secondary={
                            `${facility.properties.CITY || "Unknown City"}, ${
                              facility.properties.COUNTRY || "Unknown Country"
                            }`
                          }
                          secondaryTypographyProps={{ color: "gray", fontSize: '0.75rem' }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          }
        })}
      </List>
    </Box>
  );
};

export default FacilityFinder;