import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  FormControlLabel,
  Switch,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Drawer,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import { useUser } from './UserContext';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import FacilityFinder from './FacilityFinder';

const UserProfilePanel = ({ handleSignOut, datacentersData }) => {
  const { user, userPreferences, setUserPreferences } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const togglePanel = () => setIsPanelOpen(!isPanelOpen);

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedFacility(null);
  };

 const handleAddDatacenter = () => {
    if (selectedFacility && userPreferences.facilitiesSettings.datacenterPreLoad.length < 3) {
      const newDatacenter = {
        displayName: selectedFacility.properties.FACILITY,
        value: selectedFacility.geometry.coordinates
      };
      const updatedDatacenterPreLoad = [...userPreferences.facilitiesSettings.datacenterPreLoad, newDatacenter];
      setUserPreferences({
        ...userPreferences,
        facilitiesSettings: {
          ...userPreferences.facilitiesSettings,
          datacenterPreLoad: updatedDatacenterPreLoad
        }
      });
      handleCloseDialog();
    }
  }; 

  const handleDeleteDatacenter = () => {
    const updatedDatacenterPreLoad = userPreferences.facilitiesSettings.datacenterPreLoad.filter(
      (_, index) => !userPreferences.facilitiesSettings.selectedDatacenters.includes(index)
    );
    setUserPreferences({
      ...userPreferences,
      facilitiesSettings: {
        ...userPreferences.facilitiesSettings,
        datacenterPreLoad: updatedDatacenterPreLoad,
        selectedDatacenters: []
      }
    });
  };

  const handleCheckboxChange = (index) => {
    const updatedSelectedDatacenters = userPreferences.facilitiesSettings.selectedDatacenters.includes(index)
      ? userPreferences.facilitiesSettings.selectedDatacenters.filter(i => i !== index)
      : [...userPreferences.facilitiesSettings.selectedDatacenters, index];
    setUserPreferences({
      ...userPreferences,
      facilitiesSettings: {
        ...userPreferences.facilitiesSettings,
        selectedDatacenters: updatedSelectedDatacenters
      }
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    saveUserPreferences();
  };

  const handleSignOutClick = () => {
    handleSignOut();
    handleClose();
  };

  const handleShowFullMapChange = (event) => {
    setUserPreferences({
      ...userPreferences,
      defaultSettings: {
        ...userPreferences.defaultSettings,
        showFullMap: event.target.checked
      }
    });
  };

  const handleShowSeaportsChange = (event) => {
    setUserPreferences({
      ...userPreferences,
      defaultSettings: {
        ...userPreferences.defaultSettings,
        showSeaports: event.target.checked
      }
    });
  };

  const handleShowAirportsChange = (event) => {
    setUserPreferences({
      ...userPreferences,
      defaultSettings: {
        ...userPreferences.defaultSettings,
        showAirports: event.target.checked
      }
    });
  };

  const handleDefaultViewChange = (event) => {
    setUserPreferences({
      ...userPreferences,
      facilitiesSettings: {
        ...userPreferences.facilitiesSettings,
        defaultView: event.target.value
      }
    });
  };

  const handleMapDefaultStyleChange = (event) => {
    setUserPreferences({
      ...userPreferences,
      defaultSettings: {
        ...userPreferences.defaultSettings,
        mapDefaultStyle: event.target.value
      }
    });
  };

  const saveUserPreferences = async () => {
    if (user) {
      setIsLoading(true);
      try {
        const userRef = doc(db, 'users', user.uid);
        await setDoc(userRef, {
          defaultSettings: userPreferences.defaultSettings,
          facilitiesSettings: userPreferences.facilitiesSettings
        }, { merge: true });
        console.log('User preferences saved successfully');
      } catch (error) {
        console.error('Error saving user preferences:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getInitials = (name) => {
    if (!name) return 'D';
    return name.charAt(0).toUpperCase();
  };

  return (
    <>
      <Avatar
        onClick={togglePanel}
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: '8px',
          right: '8px',
          zIndex: 1100,
        }}
      >
        {getInitials(user?.displayName)}
      </Avatar>
      <Drawer
        anchor="right"
        open={isPanelOpen}
        onClose={togglePanel}
        PaperProps={{
          sx: {
            width: 300,
            top: '64px',
            height: 'calc(100% - 64px)',
          }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
            <Avatar>{getInitials(user?.displayName)}</Avatar>
            <Box>
              <Typography variant="subtitle1">{user?.displayName? user?.displayName: 'Demo (name not set)'}</Typography>
              <Typography variant="body2">{user?.email}</Typography>
            </Box>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">User Preferences</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={userPreferences.defaultSettings.showFullMap}
                onChange={handleShowFullMapChange}
              />
            }
            label="Show Full Map"
          />
          <FormControlLabel
            control={
              <Switch
                checked={userPreferences.defaultSettings.showSeaports}
                onChange={handleShowSeaportsChange}
              />
            }
            label="Show Seaports"
          />
          <FormControlLabel
            control={
              <Switch
                checked={userPreferences.defaultSettings.showAirports}
                onChange={handleShowAirportsChange}
              />
            }
            label="Show Airports"
          />
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Map Default Style</Typography>
            <Select
              fullWidth
              value={userPreferences.defaultSettings.mapDefaultStyle}
              onChange={handleMapDefaultStyleChange}
            >
              <MenuItem value="satellite">Satellite</MenuItem>
              <MenuItem value="roads">Roads</MenuItem>
              <MenuItem value="night">Night</MenuItem>
            </Select>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Facilities Default View</Typography>
            <Select
              fullWidth
              value={userPreferences.facilitiesSettings.defaultView}
              onChange={handleDefaultViewChange}
            >
              <MenuItem value="satellite">Satellite</MenuItem>
              <MenuItem value="cesium">Cesium 3D</MenuItem>
              <MenuItem value="roads">Roads</MenuItem>
              <MenuItem value="night">Night</MenuItem>
            </Select>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Cesium Preloading</Typography>
          {userPreferences.facilitiesSettings.datacenterPreLoad.map((datacenter, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={userPreferences.facilitiesSettings.selectedDatacenters.includes(index)}
                  onChange={() => handleCheckboxChange(index)}
                />
              }
              label={datacenter.displayName}
            />
          ))}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            mt: 2,
            mx: '10px',
          }}>
            <Button
              variant="contained"
              onClick={handleOpenDialog}
              disabled={userPreferences.facilitiesSettings.datacenterPreLoad.length >= 3}
              sx={{
                flex: 1,
                mr: '3px',
                backgroundColor: '#BFECFF',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#5a5959',
                },
                '&:disabled': {
                  backgroundColor: '#ccc',
                  color: '#666',
                },
              }}
            >
              Add
            </Button>
            <Button
              variant="contained"
              onClick={handleDeleteDatacenter}
              disabled={!userPreferences.facilitiesSettings.selectedDatacenters.length}
              sx={{ 
                flex: 1, 
                ml: '3px',
                backgroundColor: '#BFECFF',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#5a5959',
                },
                '&:disabled': {
                  backgroundColor: '#ccc',
                  color: '#666',
                }
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Drawer>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSignOutClick}>Sign Out</MenuItem>
      </Menu>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Typography variant="h6">Select Datacenter</Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FacilityFinder
            datacentersData={datacentersData}
            onFacilitySelect={setSelectedFacility}
            selectedFacility={selectedFacility}
            mode="datacenters"
            leftPanelRef={null} 
            rightPanelStatus={false} 
            onClose={handleCloseDialog}
          /> 
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAddDatacenter}
            disabled={!selectedFacility || userPreferences.facilitiesSettings.datacenterPreLoad.length >= 3}
            sx={{
              backgroundColor: '#BFECFF',
              color: 'black',
              '&:hover': {
                backgroundColor: '#5a5959',
              },
            }}
          >
            Add
          </Button>
          <Button onClick={handleCloseDialog} sx={{
            backgroundColor: '#BFECFF',
            color: 'black',
            '&:hover': {
              backgroundColor: '#5a5959',
            },
          }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog> 
    </>
  );
};

export default UserProfilePanel;