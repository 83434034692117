import React from "react";
import { Box, IconButton, Typography, Divider, Button } from "@mui/material";
import StorageIcon from '@mui/icons-material/Storage';
import HubIcon from '@mui/icons-material/Hub';
import FactoryIcon from '@mui/icons-material/Factory';
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import CloseFullscreenIcon from '@mui/icons-material/FlipToBack';
import CircleIcon from '@mui/icons-material/Circle';

const NavigationLeft = ({ onFacilitySelect, setMode, isFilterPanelOpen, selectedMode, handleLeftPanelVisibility }) => {
  const [activeButton, setActiveButton] = React.useState(null);
  const [facilityFinderState, setFacilityFinderState] = React.useState({
    global: false,
    orders: false,
    shipping: false,
    schedule: false,
  });

  const handleClick = (facilityFinderType) => {
    const type = facilityFinderType;
    setFacilityFinderState((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      newState[facilityFinderType] = true;

      return newState;
    });
    setMode(type);
    setActiveButton(facilityFinderType);
  };

  const NavButton = ({ icon: Icon, label, type }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ml: 2 }}>
    <IconButton
      onClick={() => handleClick(type)}
      sx={{
        position: 'relative',
        width: 32,
        height: 32,
      }}
    >
      <CircleIcon 
        sx={{
          position: 'absolute',
          fontSize: 32,
          color: activeButton === type ? '#BFECFF' : 'transparent',
          transition: 'color 0.3s',
        }} 
      />
      <Icon 
        sx={{
          position: 'relative',
          zIndex: 1,
          fontSize: 16,
          color: activeButton === type ? '#000' : '#BFECFF',
          transition: 'color 0.3s',
        }} 
      />
    </IconButton>
    <Typography variant="caption" sx={{ color: '#FFF', mt: 0.5, fontSize: '10px' }}>
      {label}
    </Typography>
  </Box>
);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "6px",
          marginLeft: "10px",
          marginY: "5px",
        }}
      >
        
        <Button
          onClick={() => handleLeftPanelVisibility()}
          startIcon={<FmdGoodOutlinedIcon sx={{ color: "#BFECFF" }} />}
          sx={{ color: "#FFF", textTransform: "none", fontSize: "14px" }}
        >
          Facilities Finder
        </Button>
        <Box sx={{ width: 24, height: 24, visibility: 'hidden' }} />
        <NavButton icon={StorageIcon} label="Data Centers" type="datacenters" />
        <NavButton icon={HubIcon} label="Hubs" type="hubs" />
        <NavButton icon={FactoryIcon} label="Suppliers" type="suppliers" />
        <CloseFullscreenIcon 
          onClick={() => handleLeftPanelVisibility()}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "#BFECFF", 
            cursor: "pointer", 
          }}
        />
      </Box>
      <Divider style={{ background: "#444B4D" }} />
    </>
  );
};

export default NavigationLeft;